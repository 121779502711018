// sort-imports-ignore
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

/* eslint-disable import-x/no-unused-modules */
import 'core-js/stable';
import 'regenerator-runtime/runtime';

import * as Sentry from '@sentry/react';
import MainApp from 'main/startup/MainApp';
import mainStore from 'main/store/mainStore';
import ReactOnRails from 'react-on-rails';

import { getErrorTags, shouldIgnoreError } from 'my-core/error-utils';

// delete window.IntersectionObserver;
// delete window.IntersectionObserverEntry;
if (!('IntersectionObserver' in window)) {
  window.IntersectionObserver = class IntersectionObserverPolyfillLight {
    constructor(cb) {
      this.callback = cb;
    }
    observe(element) {
      setTimeout(
        () => this.callback([{ isIntersecting: true, intersectionRatio: 1, isVisible: true, target: element }]),
        0,
      );
    }
    unobserve() {}
    disconnect() {}
  };
  import(/* webpackChunkName: "polyfills" */ 'intersection-observer');
}

// const DEBUG_AGENT = !!(window?.navigator?.userAgent?.includes('SM-A525F') || __DEV__);

if (process.env.SENTRY_CLIENT_DSN) {
  // https://docs.sentry.io/platforms/javascript/configuration/options/
  Sentry.init({
    dsn: process.env.SENTRY_CLIENT_DSN,
    release: process.env.HEROKU_SLUG_COMMIT,
    environment: process.env.NODE_ENV,
    // maxBreadcrumbs: DEBUG_AGENT ? 1000 : 100,
    // attachStacktrace: DEBUG_AGENT,

    // add event tags to sentry events. References:
    // https://docs.sentry.io/platforms/javascript/configuration/filtering/
    beforeSend(event, hint) {
      const error = hint.originalException;
      if (shouldIgnoreError(error)) return null;
      event.tags = { ...event.tags, ...getErrorTags(error) };
      return event;
    },
    beforeBreadcrumb(event) {
      if (event.category === 'xhr' || event.category === 'fetch') {
        if (
          [
            /mixpanel/,
            /google-analytics/,
            /analytics\.google/,
            /hotjar/,
            /doubleclick\.net/,
            /pagead2\.googlesyndication\.com/,
            /js\.intercomcdn.com/,
            /googletagmanager.com/,
            /gist\.build/, // Customer.io in-app messages
            /cloudfront\.net.*\.(ts|aac|vtt)$/, // HLS video segments fetched many times
          ].some(regex => regex.test(event.data.url))
        ) {
          return null;
        }
      }
      return event;
    },
    denyUrls: [
      // /extensions\//i,
      /^(chrome|safari)(-extension)?:\/\//i,
      'js.intercomcdn.com',
      'googletagmanager.com',
      'googleads.g.doubleclick.net',
      'pagead2.googlesyndication.com',
      // 'accounts.google.com', // temporarily unhide to debug google signin issue https://wize-lr.sentry.io/issues/2889128426/events/102c2cd0c5014cf297d0f59e47efe716/
      'analytics.tiktok.com',
      'js-agent.newrelic.com',
      'connect.facebook.net',
      /(script|static)\.hotjar\.com/,
      /gist\.build/, // Customer.io in-app messages
    ],
  });
}

ReactOnRails.registerStore({ mainStore });

// This is how react_on_rails can see the MainApp in the browser.
ReactOnRails.register({ MainApp });
