import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import { Link, useMatch } from 'react-router';

import WizeLogo from 'my-components/WizeLogo';

const useStyles = makeStyles(
  ({ spacing }) => ({
    root: {
      display: 'block',
      flex: '0 0 40px',
      marginRight: spacing(1),
    },

    logo: { display: 'block' },
  }),
  { name: 'AppBarLogo' },
);

export default function AppBarLogo(props) {
  const { logoDashClassName, logoTickClassName, to } = props;
  const classes = useStyles(props);

  const match = useMatch(to);

  const logo = (
    <WizeLogo
      classes={{
        root: classNames(classes.logo, { [classes.root]: match }),
        logoTick: logoTickClassName,
        logoDash: logoDashClassName,
      }}
    />
  );

  if (match) {
    return logo;
  } else {
    return (
      <Link className={classes.root} to={to}>
        {logo}
      </Link>
    );
  }
}
